import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
let ThemesMixin = class ThemesMixin extends Vue {
    setTheme(config) {
        this.$vuetify.theme.themes.light = config.theme;
    }
    setFavicon(config) {
        const favicon = document.getElementById('favicon');
        favicon.href = require(`@/assets/favicons/${config.favicon}`);
    }
};
ThemesMixin = tslib_1.__decorate([
    Component
], ThemesMixin);
export default ThemesMixin;
