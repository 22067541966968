import * as tslib_1 from "tslib";
import Vue from 'vue';
import emailsValidator from '@/validators/emails';
import passwordsValidator from '@/validators/passwords';
import { Prop, Component, Emit } from 'vue-property-decorator';
import LanguageSwitcher from '@/components/LanguageSwitcher/LanguageSwitcher.vue';
let LoginForm = class LoginForm extends Vue {
    constructor() {
        super(...arguments);
        this.isShowPassword = false;
        this.email = '';
        this.password = '';
        this.validationRules = {
            email: [],
            password: [],
        };
    }
    get loginForm() {
        return this.$refs.loginForm;
    }
    applyValidationRules() {
        this.validationRules.email.push(emailsValidator.validateExists, emailsValidator.validateFormat);
        this.validationRules.password.push(passwordsValidator.validateExists);
    }
    handleLogin(e) {
        e.preventDefault();
        this.applyValidationRules();
        if (this.loginForm.validate()) {
            this.executeLogin();
        }
    }
    executeLogin() {
        return {
            email: this.email,
            password: this.password,
        };
    }
    togglePassword() {
        this.isShowPassword = !this.isShowPassword;
    }
};
tslib_1.__decorate([
    Prop(String)
], LoginForm.prototype, "authErrorMessage", void 0);
tslib_1.__decorate([
    Prop(Boolean)
], LoginForm.prototype, "isDisabled", void 0);
tslib_1.__decorate([
    Prop(Boolean)
], LoginForm.prototype, "isLoading", void 0);
tslib_1.__decorate([
    Prop(Object)
], LoginForm.prototype, "config", void 0);
tslib_1.__decorate([
    Emit('execute-login')
], LoginForm.prototype, "executeLogin", null);
LoginForm = tslib_1.__decorate([
    Component({
        components: { LanguageSwitcher },
    })
], LoginForm);
export default LoginForm;
