import apiUtil from '../../utils/api';
import domainUtil from '../../utils/domain';
const AUTH = {
    SUBDOMAIN: 'auth',
    LOGIN_PATH: '/auth/api/login',
};
function buildUrl(subDomain, path = '') {
    return 'https://' + subDomain + '.' + domainUtil.getDomain() + path;
}
export default {
    async login(email, password, landingApp = 'home') {
        await apiUtil.post(buildUrl(AUTH.SUBDOMAIN, AUTH.LOGIN_PATH), { email, password });
        return buildUrl(landingApp);
    },
};
