import * as tslib_1 from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import defaultConfig from '../configs/default.json';
import configsService from './services/configs';
import ConfigSwitcher from '@/components/ConfigSwitcher';
import envUtil from '@/utils/env';
import ThemesMixin from '@/mixins/ThemesMixin';
let App = class App extends Mixins(ThemesMixin) {
    constructor() {
        super(...arguments);
        this.config = defaultConfig;
        this.isLoading = true;
        this.configsService = configsService;
    }
    async created() {
        try {
            this.config = await this.configsService.getConfig();
        }
        finally {
            this.changeTheme(this.config);
            this.isLoading = false;
        }
    }
    changeTheme(newConfigs) {
        this.config = newConfigs;
        this.setTheme(this.config);
        this.setFavicon(this.config);
    }
    isDevEnv() {
        return envUtil.isDevEnv();
    }
};
App = tslib_1.__decorate([
    Component({
        components: { ConfigSwitcher },
    })
], App);
export default App;
