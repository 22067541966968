import * as tslib_1 from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import loginService from '@/services/login';
import LanguageSwitcher from '@/components/LanguageSwitcher';
import LoginForm from '@/components/Login/Form';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.authErrorMessage = '';
        this.isLoggingIn = false;
    }
    async executeLogin({ email, password }) {
        try {
            this.isLoggingIn = true;
            this.clearAuthErrorMessage();
            const redirectUrl = await loginService.login(email, password, this.config.landingApp);
            location.replace(redirectUrl);
        }
        catch (err) {
            this.authErrorMessage = err.response.data.message || this.generateErrorMessage(err.response.status);
            this.isLoggingIn = false;
        }
    }
    generateErrorMessage(status) {
        return `Error logging in. Status ${status} error.`;
    }
    clearAuthErrorMessage() {
        this.authErrorMessage = '';
    }
};
tslib_1.__decorate([
    Prop(Object)
], Login.prototype, "config", void 0);
Login = tslib_1.__decorate([
    Component({
        components: {
            LanguageSwitcher,
            LoginForm,
        },
    })
], Login);
export default Login;
