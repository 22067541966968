import * as tslib_1 from "tslib";
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
let Switcher = class Switcher extends Vue {
    constructor() {
        super(...arguments);
        this.appConfigsList = this.buildAppConfigsList();
        this.currentConfig = {
            name: '',
            value: this.config,
        };
    }
    onPropertyChanged(newValue) {
        this.$emit('configs-changed', newValue);
    }
    buildAppConfigsList() {
        return require.context('@/../configs', false, /\.json$/)
            .keys()
            .map(config => {
            const configName = config.replace(/(\.\/|\.json)/g, '');
            return {
                name: configName.toUpperCase(),
                value: require(`@/../configs/${configName}.json`),
            };
        });
    }
};
tslib_1.__decorate([
    Prop(Object)
], Switcher.prototype, "config", void 0);
tslib_1.__decorate([
    Watch('currentConfig')
], Switcher.prototype, "onPropertyChanged", null);
Switcher = tslib_1.__decorate([
    Component
], Switcher);
export default Switcher;
