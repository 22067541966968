import axios from 'axios';
async function call(method, url, data, query, headers) {
    const response = await axios(url, {
        method,
        data,
        params: query,
        headers,
        withCredentials: true,
    });
    return response.data;
}
export default {
    get(url, headers, query) {
        return call('get', url, {}, query, headers);
    },
    post(url, data, headers, query) {
        return call('post', url, data, query, headers);
    },
};
