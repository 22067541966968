function getHostnameList() {
    return window.location.hostname.split('.');
}
export default {
    getDomain() {
        const hostnameList = getHostnameList();
        hostnameList.shift();
        return hostnameList.join('.');
    },
    getHostname() {
        return window.location.hostname;
    },
};
