import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import 'vue-material-design-icons/styles.css';
import i18n from './plugins/i18n';
Vue.config.productionTip = false;
new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app');
