const emailRegex = /.+@.+\..+/;
class EmailsValidator {
    validateExists(email) {
        return !!email || 'Email is required.';
    }
    validateFormat(userEmail) {
        return emailRegex.test(userEmail) || 'Email is not valid.';
    }
}
export default EmailsValidator;
