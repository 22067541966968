import * as tslib_1 from "tslib";
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
const DEFAULT_LOCALE = process.env.VUE_APP_I18N_LOCALE;
let LanguageSwitcher = class LanguageSwitcher extends Vue {
    constructor() {
        super(...arguments);
        this.localeList = [
            { text: 'English (US)', value: 'en' },
            { text: 'Bahasa Indonesia', value: 'id' },
        ];
        this.locale = DEFAULT_LOCALE;
    }
    mounted() {
        this.locale = this.$i18n.locale;
    }
    onPropertyChanged(newLocale) {
        this.$i18n.locale = newLocale;
    }
};
tslib_1.__decorate([
    Watch('locale')
], LanguageSwitcher.prototype, "onPropertyChanged", null);
LanguageSwitcher = tslib_1.__decorate([
    Component
], LanguageSwitcher);
export default LanguageSwitcher;
;
