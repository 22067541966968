import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        component: Login,
    },
];
const router = new VueRouter({
    mode: 'history',
    routes,
});
export default router;
